import * as React from "react"
import { Link, graphql } from "gatsby"

const IndexPage = ({ data }) => {
  return (
    <>
      <h1>Layoutless !!</h1>
      <p>
        <Link to="/">Back to HOME</Link> <br />
      </p>

    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
